import React from "react"
import Link from "gatsby-link"
import logo from '../../images/tc_logo.svg'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


const Header = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
    this.isActive = {
      slug: '',
      linkActiveClass: '',
    }
  }


  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav className="navbar is-white is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand pl-1">
          <div class="navbar-item is-tab" >
            <Link title="Logo" to="/" style={{display: "flex"}}>
              <img src={logo} alt="Travel Connection" style={{height: "100%", width: "170px", paddingLeft: "0rem"}}/>
            </Link>
          </div>

          <span aria-hidden="true"></span>
          <a role="button" class={ `navbar-burger burger ${this.state.navBarActiveClass}`}
          aria-label="menu" aria-expanded="false"
          data-target="navbarBasicExample"

          onClick ={() => this.toggleHamburger()}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

    <div id="navbarBasicExample" className={`navbar-menu ${this.state.navBarActiveClass}`} >
      <div class="navbar-end is-light pr-2">

        <Link to="/service">
          <div class="navbar-item is-tab">
            <button class="button is-primary is-inverted">
              Service
            </button>
          </div>
        </Link>

        <Link to="/reiseberichte">
          <div class="navbar-item is-tab">
            <button class="button is-primary is-inverted">
              Reiseberichte
            </button>
          </div>
        </Link>

        <Link to="/ueber-uns">
          <div class="navbar-item is-tab">
            <a class="button is-primary is-inverted">
              Über Uns
            </a>
          </div>
        </Link>

        <div class="navbar-item" >
        <OutboundLink href="tel:+4921739841841">
        <button class="button is-primary is-outlined">
        <span class=" pl-1 pr-2 icon is-primary">
          <i class="fas fa-phone"></i>
          </span>
          02173 - 9841 841
        </button>
        </OutboundLink>
        </div>

      </div>

  </div>

  </nav>
    )
  }
}

export default Header
