/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import siteConfig from '../../../data/siteConfig'
import HeroHeader from "../Hero/hero"
import Header from "../Header/header"


import SEO from "../SEO/seo"
import Footer from "../Footer/footer"
import './layout.scss'
import { configureAnchors } from 'react-scrollable-anchor'

class Layout extends React.Component {


  render () {
  configureAnchors({offset: -60, scrollDuration: 3000})
  const {heroURL, title, subTitle, slug } = this.props
  const children = this.props.children

  return (
      <>
        <SEO title={slug} />
        <Header/>
        <div style={{paddingTop: "4rem"}}>
        {heroURL && (
          <HeroHeader
          heroURL={heroURL}
          title = {title}
          subTitle = {subTitle}/>
        ) }
        {children}
        </div>
        <Footer siteConfig={siteConfig} style={{zIndex: "500"}}/>
      </>
    );
  }
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
