module.exports = {
  siteTitle: 'Reisebüro Travel Connection',
  siteDescription: "Reisebüro Travel Connection - Ihr Partner für schönes Reisen in Langenfeld-Richrath.",
  siteUrl: 'https://schoenesreisen.de',
  // Prefixes all links. For cases when deployed to maxpou.fr/gatsby-starter-morning-dew/
  pathPrefix: '/', // Note: it must *not* have a trailing slash.
  googleAnalyticsId: 'UA-151019931-1',
  contentfulSpaceId: 'wm9p0wxrtkh3',
  contenfulAccessToken: 'n8tcmzU4K1jPixapsjS6RGuBA-u9OyswnLcjCZr4qdo',
  background_color: '#ffffff',
  theme_color: '#000000',
  display: 'standalone',
  icon: `src/images/tc_sun.svg`,
}
