import React from "react"

class HeroHeader extends React.Component{
    render () {
    const {heroURL, title, subTitle} = this.props;
    return (
      <>
        <section class="hero is-fullheight faded-bottom"
        style={{background: `url(${heroURL})`,
        boxShadow: "inset 9px 0px 400px -21px rgba(252,252,252,0.99)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"}}>


          <div class="hero-body mt10-">
            <div class="container">
              <h1 class="title is-bold has-text-white is-uppercase has-text-weight-light">
                {title}
              </h1>
              <h2 class="subtitle has-text-weight-bold has-text-white">
                {subTitle}
              </h2>
            </div>
          </div>
      </section>
    </>
  )
}

}

export default HeroHeader;
