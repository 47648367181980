import React from 'react'
import logo from '../../images/tc_logo_w.svg'
import { Link } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

class Footer extends React.Component {
  render() {

    return (
      <footer class="footer">
        <div class="columns pl-25 pr-25 pt-2">
          <div class="column">
            <div class="content has-text-left">
              <h4>Kontakt</h4>
              <p class="is-white">Kaiserstr. 34 <br/> 40764 Langenfeld <br/> Tel. 02173 - 9841 841 </p>

                <OutboundLink href="https://www.facebook.com/travelconnectionTouristik">
                  <span class="pr-2 pl-1 icon has-text-white is-small fa-2x">
                    <i class="fab fa-facebook"></i>
                  </span>
                </OutboundLink>

                <OutboundLink href="mailto:info@schoenesreisen.de">
                  <span class="pr-2 pl-2 icon has-text-white is-small fa-2x">
                    <i class="far fa-envelope"></i>
                  </span>
                </OutboundLink>

              <br/>
              <p class="is-white">Öffnungszeiten <br/> Mo - Sa: 10.00 - 13:00 Uhr <br/> Mo, Di, Do und Fr: : 15.00 - 18:00 Uhr <br/> <br/> </p>
            </div>
          </div>
          <div class="column">
            <div class="content has-text-left pt-1 pb-1">
              <img src={logo} alt="Travel Connection" style={{color: "white", width: "100%"}}/>
              <h3>Einfach schönes Reisen.</h3>
            </div>
          </div>
          <div class="column">
            <div class="content has-text-left has-text-right">
              <h4>Links</h4>
              <p class="is-white">
                <Link to="/service" style={{color: "white"}}>
                  Service
                </Link>
              <br/>
                <Link to="/reiseberichte" style={{color: "white"}}>
                  Reiseberichte
                </Link>
              <br/>
                <Link to="/ueber-uns" style={{color: "white"}}>
                  Über Uns
                </Link>

              <br/><br/>
                <Link to="/impressum" style={{color: "white"}}>
                  Impressum
                </Link>
              <br/>
                <Link to="/datenschutz" style={{color: "white"}}>
                Datenschutzerklärung
                </Link>
              <br/>
                <Link to="/sitemap.xml" style={{color: "white"}}>
                  Sitemap
                </Link>
              <br/>

              </p>

              <p class="is-white">

              Copyright <strong class="is-white">©</strong> 2019 travel connection Touristik GmbH
              </p>
            </div>
          </div>
          <br/> <br/>
        </div>
      </footer>
    )
  }
}

export default Footer
